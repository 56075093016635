@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .tx-editing-color {
    @apply bg-yellow-200;
  }

  .tx-selected-color {
    @apply bg-blue-200;
  }

  .tx-reconciled-text-color {
    @apply text-gray-600;
  }

  .button {
    @apply px-2 py-1 rounded-md m-1 border;

    &.button-danger {
      @apply bg-red-300 border-red-400;
      @apply hover:bg-red-400;
      &:disabled {
        @apply bg-red-100 text-gray-400;
      }
    }

    &.button-default {
      @apply bg-blue-300 border-blue-400;
      @apply hover:bg-blue-400;
      &:disabled {
        @apply bg-blue-100 text-gray-400;
      }
    }

    &.button-primary {
      @apply bg-green-300 border-green-400;
      @apply hover:bg-green-400;
      &:disabled {
        @apply bg-green-100 text-gray-400;
      }
    }
  }

  .form {
    @apply p-2;

    input {
      @apply p-1 border rounded-md border-gray-400;
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.select-option {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

input:focus-visible {
  padding-left: 4px;
  outline-offset: 0px;
}
